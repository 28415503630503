import React from 'react'
import { Link } from 'gatsby'
import { Flex, Button, Heading, Text } from 'theme-ui'
import { FaRegHandshake } from 'react-icons/fa'

const styles = {
  wrapper: {
    alignItems: `center`,
    flexDirection: `column`,
    bg: `omegaLighter`,
    borderRadius: `lg`,
    size: `full`,
    p: 4,
  },
  heading: {
    color: `omegaDark`,
    // wordSpacing: 400, //force line breaks
    marginRight: `auto`,
    svg: {
      color: `beta`,
      size: `icon.lg`,
      display: `block`,
      mb: 3,
    },
  },
  subheading: {
    color: `omegaDark`,
    fontWeight: `normal`,
  },
  list: {
    color: `omegaDark`,
    listStyle: `none`,
    m: 0,
    p: 0,
    li: {
      p: 0,
      my: 2,
    },
    'li:before': {
      content: `""`,
      display: `inline-block`,
      width: `icon.xs`,
      bg: `success`,
      borderRadius: `full`,
      size: `7px`,
      mr: `7px`,
    },
  },
  button: {
    display: `block`,
    mt: `auto`,
  },
}

const BannerVertical = () => (
  <Flex sx={styles.wrapper}>
    <Heading variant='h2' sx={styles.heading}>
      <FaRegHandshake  color='#667eea' />
      Faça Parte!
    </Heading>
    <Heading variant='h4' sx={styles.subheading}>
      O admina é grupo de apoio na carreira de Produto e UX, sem objetivo financeiro e totalmente sem custo. 
      Para fazer parte:     
    </Heading>
    <Text as='ul' variant='small' sx={styles.list}>
      <li>Clique no botão abaixo</li>
      <li>Acesse o formulário no fim da página</li>
      <li>Em alguns dias, você receberá um e-mail com os próximos passos ;)</li>
    </Text>
    <Button
      variant='primary'
      as={Link}
      to='/about'
      sx={styles.button}
      aria-label='Saiba mais'
    >
      Saiba mais
    </Button>
  </Flex>
)

export default BannerVertical
